/**
 * Checks if a name is safe to use as a JS property name
 */
export function isSafeProp(prop: string): boolean {
	return !(prop in {})
}

/**
 * Throws if a name is not safe to use as a JS property name
 */
export default function safeProp<T>(prop: T): T {
	if (!isSafeProp(prop + '')) throw new Error(`Prop ${prop} is not safe`)
	return prop
}
